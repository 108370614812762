<template>
	<div class="top-nav">
		<!-- <img class="home-left" src="~@/assets/img/nav/back.png" alt="" @click="goBack(1)"> -->
		<img class="home-left" src="~@/assets/img/order/back-left.png" alt="" @click="goBack(1)">
		<img class="home-logo" :src="$store.state.headUrl" alt="">
		<img width="48px" height="48px" src="~@/assets/img/order/back-home.png" alt="" @click="goBack(2)">
	</div>
</template>

<script>
	export default {
		name: 'TopNavBar',
		methods: {
			goBack(type) {
				switch (type) {
					case 1:
						// this.$router.go(-1)
						this.$router.back(-1)
						break
					case 2:
						this.$router.push({
							name: 'Home',
							query: {
								shopUuid: this.$store.state.shopUuid
							}
						})
						break
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.top-nav {
		height: 59.5px;
		padding: 15px;
		background: #fff;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.home-logo {
		height: 28.5px;
		// width: 112px;
	}

	.home-left,
	.home-right {
		width: 28.5px;
		height: 28.5px;
	}
</style>
